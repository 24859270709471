.team-section {
  display: flex;
  justify-content: flex-start;
  /* Align items to the left */
  padding: 0px 20px;
  max-width: 100%;
  /* Make sure the section spans the full width */
  margin-bottom: var(--section-space);
}

.team-description {
  flex: 1;
  /* Take up available space */
  text-align: justify;
  /* Justify the text */
  font-size: 1.1rem;
  /* Adjust font size as needed */
  line-height: 1.6;
  /* Improve readability */
  color: #333;
  margin-bottom: var(--section-space);
}

@media (max-width: 800px) {
  .team-section {
    flex-direction: column;
    /* Stack vertically on smaller screens */
  }
}

.team-section h2 {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 40px;
  color: #003366;
}

.team-container {
  max-width: 100%;
  margin: 0 auto;
}