.finops-section {
  padding: 0px 20px;
  text-align: center;
  margin-bottom: var(--section-space);
}

.finops-section h2 {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 40px;
  color: #003366;
}

.finops-container {
  max-width: 100%;
  margin: 0 auto;
}

.finops-title {
  font-size: 2rem;
  color: #003366;
  margin-bottom: 20px;
}

.finops-description {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #333;
  text-align: justify;
  /* Justify the text */
  margin-bottom: 20px;
}