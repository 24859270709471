.info-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* Center the title horizontally */
  padding: 0px 20px;
  max-width: 100%;
  /* Ensure it takes up the full width */
  margin-bottom: var(--section-space);
}

.info-title {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 40px;
  color: #003366;
  text-align: center;
}

.info-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  /* Ensure content spans full width */
}

.info-text {
  flex: 1;
  text-align: justify;
  padding-right: 20px;
  font-size: 1.1rem;
  line-height: 1.6;
}

.info-text p {
  margin-bottom: 20px;
}

.info-image {
  flex: 0.7;
  display: flex;
  justify-content: center;
  /* Center image inside container */
  align-items: center;
}

.info-image img {
  max-width: 100%;
  border-radius: 10px;
  /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Light shadow */
}

/* Responsive behavior */
@media (max-width: 800px) {
  .info-content {
    flex-direction: column;
    /* Stack text and image vertically on smaller screens */
  }

  .info-text {
    padding-right: 0;
    margin-bottom: 20px;
  }
}