.feature-section {
  padding: 0px 20px;
  text-align: center;
  margin-bottom: var(--section-space);
}

.feature-section h2 {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 40px;
  color: #003366;
}

.feature-grid {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(1, 1fr);
  /* Default for small screens */
}

/* 600px and above: 2 columns */
@media (min-width: 600px) and (max-width: 799px) {
  .feature-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* 800px and above: 3 columns */
@media (min-width: 800px) {
  .feature-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

.feature-card {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: left;
  transition: transform 0.3s;
}

.feature-card:hover {
  transform: translateY(-5px);
  /* Slight hover effect */
}

.feature-icon {
  font-size: 3rem;
  color: #003366;
  /* Dark blue color for icons */
  margin-bottom: 10px;
}

.feature-title {
  font-size: 1.3rem;
  margin-bottom: 10px;
}

.feature-description {
  font-size: 1.1rem;
  color: #333;
  line-height: 1.6;
  /* text-align: justify; */
}