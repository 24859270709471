.call-button {
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1.1rem;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.call-button:hover {
  background-color: var(--primary-color-hover);
}