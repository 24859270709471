.footer-section {
  padding: 0px 20px 20px 20px;
}

.footer-line {
  margin-bottom: 20px;
  border: none;
  height: 1px;
  background-color: #e0e0e0;
}

.footer-content {
  font-size: 1.1rem;
  display: flex;
  justify-content: space-between;
  /* Pushes content to edges */
  align-items: center;
}

.footer-copyright {
  color: #333;
  margin: 0;
}

.linkedin-link {
  color: #0077b5;
  /* LinkedIn blue */
  text-decoration: none;
}

.linkedin-link:hover {
  color: #005582;
  /* Darker blue on hover */
}