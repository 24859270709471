.timeline-section {
  padding: 0px 20px;
  text-align: center;
  margin-bottom: var(--section-space);
}

.timeline-section-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 500px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.timeline-section h2 {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 40px;
  color: #003366;
}

.timeline-section .my-card-title {
  padding-left: 10px !important;
}